import Vue from 'vue'
import Vuex from 'vuex'
import axios, {ApiResponse} from "@/plugins/axios";
import Swal from "sweetalert2-khonik";
import domainModule from "@/store/domain";
import smtpModule from "@/store/smtp";
import smsModule from "@/store/sms";
import printerModule from "@/store/printer";

Vue.use(Vuex)


export interface AppState {
    routes: any[]
    jwt?: string
    user?: IUser
    company?: ICompany
    branch?: IBranch
    attachedCustomer?: any
    detachedCustomer?: any
    accessLevels: any[]
    need_branch: boolean
    custom_host: boolean
    available_branch_select: boolean
    permissions: any
}

export type EntityType = "entity" | "entrepreneur" | "self_employed";

export interface IShortSettings {
    id: number
    brand_name: string
    logo: string
    primary_color: string
}

export interface ICompany {
    id: number
    access_level: number
    name: string
    subscription?: ISubscription
    settings?: IShortSettings
}

export interface IBranch {
    id: number
    name: string
    access_level: number
}

export interface ISubscription {
    amount: number
    expires_at: string
    users_count: number
    modules: IModule[]
}

export interface IModule {
    id: number
    key: string
    name: string
    description: string
}

export interface ICreateCompanyRequest {
    name: string
}

export interface IPlan {
    id: number
    is_default: boolean
    name: string
    users_count: number
    duration: number
    price: number
}

export interface IModule {
    id: number
    key: string
    name: string
    price: number
}

export interface IUser {
    id: number
    customer_id: number
    name: string
    surname: string
    father_name: string // new
    email: string
    email_verified_at: string
    phone: string
    telegram: string
    balance: number
    created_at: string
    last_activity_date: string
    tg_username: string
    tg_channel_id: string
    company_context_id: number
    branch_context_id: number | null
    is_admin: boolean
}

export interface ISettings {
    entity_type: EntityType
    brand_name: string
    legal_name: string
    legal_full_name: string
    legal_statement: string
    ogrn: string
    inn: string
    kpp: string // new
    okpo: string // new
    okved: string // new
    city: string
    address: string
    legal_address: string // new
    ceo_name: string // new
    ceo_name_genitive: string // new
    ceo_title_genitive: string // new
    bank: string
    rs: string
    ks: string
    bik: string
    phone: string
    email: string
    website: string | undefined
    notification_time: string
    logo: string
    domain: string
    domain_verified: boolean
    mail_footer: string
    director_sign: string
    stamp: string
    primary_color: string //new
}

export type AccountType = "cash" | "card" | "account" | "acquiring"

export interface IAccount {
    type: AccountType // new
    selected: boolean // new
    name: string
    balance: number
    expected_income: number
    prepayment: number
    details: string
}

export const CompanyEntityTypes = [
    {
        key: "entity", value: "Юридическое лицо",
    },
    {
        key: "entrepreneur", value: "Индивидуальный предприниматель (ИП)",
    },
    {
        key: "self_employed", value: "Самозанятый / физ. лицо",
    },
]

const isRouteVisible = (r: any, permissions: any) => {
    if (r.children) {
        r.children = r.children.filter((r: any) => isRouteVisible(r, permissions))
        return r.children.length > 0;
    } else {
        if (!r.key) return true;
        const permission = permissions[r.key];
        return permission["viewAny"];
    }
}

export default new Vuex.Store({
    state: <AppState>{
        routes: [
            {title: 'Главная', icon: 'mdi-view-dashboard', to: '/',},
            {title: 'Мониторинг', icon: 'mdi-monitor-dashboard', to: '/monitoring', key: 'monitoring'},
            {title: 'Клиенты', icon: 'mdi-toolbox', to: '/customers', key: 'customer'},
            {title: 'Наряд-заказы', icon: 'mdi-cart-outline', to: '/orders', key: 'order'},
            {title: 'Деньги', icon: 'mdi-cash', to: '/money', key: 'payment'},
            {title: 'Склад', icon: 'mdi-store', to: '/stock', key: 'part'},
            {
                title: "Справочники", icon: "mdi-palette-swatch-variant",
                children: [
                    {
                        title: 'Филиалы',
                        icon: 'mdi-source-branch',
                        to: '/branches',
                        key: 'branch'
                    },
                    {
                        title: 'Адреса доставки',
                        icon: 'mdi-truck-delivery-outline',
                        to: '/delivery-addresses',
                        key: 'deliveryAddress'
                    },
                    {title: 'Счета', icon: 'mdi-wallet-plus', to: '/accounts', key: 'account'},
                    {title: 'Сотрудники', icon: 'mdi-account-multiple', to: '/users', key: 'user'},
                    {
                        title: 'Категории расходов',
                        icon: 'mdi-credit-card-settings-outline',
                        to: '/payment-purposes',
                        key: 'paymentPurpose'
                    },
                    {
                        title: 'Источники лидов',
                        icon: 'mdi-transfer-down',
                        to: '/lead-sources',
                        key: 'leadSource'
                    },
                    {
                        title: 'Принтеры',
                        icon: 'mdi-printer-3d',
                        to: '/printers',
                        key: 'printer'
                    },
                ],
            },
            {
                title: "Инструменты", icon: "mdi-pliers",
                children: [
                    {title: 'Дедлайны', icon: 'mdi-call-split', to: '/deadline', key: 'deadline'},
                    {title: 'Рег. платежи', icon: 'mdi-cash-clock', to: '/regular-payments', key: 'regularPayment'},
                    {title: 'Задачи', icon: 'mdi-calendar-check', to: '/tasks', key: 'task'},
                    {title: 'Рассылки', icon: 'mdi-email-arrow-right-outline', to: '/newsletters', key: 'newsletter'},
                ],
            },
            {
                title: "Шаблоны", icon: "mdi-clipboard-list-outline", children: [
                    {title: 'Договора', icon: 'mdi-file-sign', to: '/contracts', key: 'contract'},
                    {title: 'Сметы', icon: 'mdi-clipboard-list', to: '/estimates', key: 'estimate'},
                ]
            },

            {title: 'Настройки', icon: 'mdi-cog', to: '/settings', key: "settings"},
            {title: 'Подписка', icon: 'mdi-shopping', to: '/subscription', key: 'subscription'},
            {title: 'Профиль', icon: 'mdi-account', to: '/profile',},
            {title: 'Поддержка', icon: 'mdi-rocket', to: '/support',},
        ],

        jwt: localStorage.getItem('access_token'),
        user: undefined as IUser | undefined,
        company: undefined as ICompany | undefined,
        branch: undefined as ICompany | undefined,
        attachedCustomer: undefined,
        detachedCustomer: undefined,
        accessLevels: [
            {key: 0, value: "Клиент"},
            {key: 1, value: "Сотрудник"},
            {key: 2, value: "Администратор"},
        ],
        need_branch: false,
        custom_host: false,
        available_branch_select: false,
        permissions: {},
    },
    getters: {
        jwt: state => state.jwt,
        user: state => state.user,
        company: state => state.company,
        routes: state => state.routes,
        visibleRoutes: state => {
            const user = <any>state.user;
            if (!user) return [];
            const company = <ICompany | undefined>state.company;
            if (!company) return [];
            return state.routes.filter(r => isRouteVisible(r, state.permissions)).map(r => {
                r.children = r.children?.filter((ch: any) => !ch.permission || ch.permission <= company.access_level);
                return r;
            });
        },
        isModerator: state => {
            return state.company && state.company.access_level > 0;
        },
        isAdmin: state => {
            return state.company && state.company.access_level > 1;
        },
        accessLevels: state => state.accessLevels,
        needBranch: state => state.need_branch,
        onCustomHost: state => state.custom_host,
        availableBranchSelect: state => state.available_branch_select,
        branch: state => state.branch,
        permissions: state => state.permissions,
        modules: state => state.company?.subscription?.modules || [],
        moduleEnabled: (state, getters) => (key: string) => {
            return !!getters.modules.find((m: IModule) => m.key === key)
        }
    },
    mutations: {
        setToken(state, token) {
            state.jwt = token;
            localStorage.setItem('access_token', token)
        },
        logout(state) {
            state.jwt = undefined;
            state.user = undefined;
            state.company = undefined;
            localStorage.removeItem('access_token')
        },
        setUser(state, user) {
            state.user = user;
        },
        needBranch(state, value) {
            state.need_branch = value;
        },
        setCustomHost(state, value) {
            state.custom_host = value;
        },
        availableBranchSelect(state, value) {
            state.available_branch_select = value;
        },
        setPermissions(state, value) {
            state.permissions = value;
        },
        attachToNewsletter(state, user) {
            state.attachedCustomer = user;
        },
        detachFromNewsletter(state, user) {
            state.detachedCustomer = user;
        },
        switchCompanyContext(state, company: ICompany) {
            state.company = company;
            if (state.user) {
                state.user.company_context_id = company.id;
            }
        },
        switchBranchContext(state, branch: IBranch) {
            state.branch = branch;
            state.need_branch = false;
            if (state.user) {
                state.user.branch_context_id = branch.id;
            }
        },

    },
    actions: {
        async getAuthUser({commit}) {
            const body = <any>await axios.get(`auth/me`);
            const user = body.user;
            commit('setUser', body.user)
            commit('needBranch', body.need_branch)
            commit('availableBranchSelect', body.available_branch_select)
            commit('setPermissions', body.permissions)
            commit('setCustomHost', body.custom_host)

            if (user.context_company) {
                commit('switchCompanyContext', user.context_company)
            }
            if (user.context_branch) {
                commit('switchBranchContext', user.context_branch)
            }
        },
        LOGOUT({state, commit}) {
            commit('logout')
        },
        async switchCompanyContext({state, commit}, companyID: number) {
            const {company} = await axios.post<any, ApiResponse>(`auth/set-context`, {
                company_id: companyID
            })
            commit('switchCompanyContext', company);
        },
        async switchBranchContext({state, commit}, branchID: number) {
            const {branch} = await axios.post<any, ApiResponse>(`auth/set-branch-context`, {
                branch_id: branchID
            })
            commit('switchBranchContext', branch);
        },

        async createCompany({}, request: ICreateCompanyRequest): Promise<ICompany> {
            const {company} = await axios.post<any, ApiResponse>(`companies`, request);
            return company;
        },
        async sendNewsletter({}, newsletter: any) {
            const result = await Swal.fire({
                title: "Подтверждаете отправку рассылки?",
                showDenyButton: false,
                showCancelButton: true,
                cancelButtonText: 'Отменить',
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonText: 'Подтверждаю',

            });

            if (result.isConfirmed) {
                try {
                    await axios.post(`newsletters/${newsletter.id}/send`);
                } catch (err: any) {
                    Swal.fire({
                        icon: "error",
                        title: "Отправка недоступна",
                        text: err.body.message,
                    });
                    throw Error("Отправка недоступна")
                }
            }
        }
    },
    modules: {
        domain: domainModule,
        smtp: smtpModule,
        sms: smsModule,
        printers: printerModule
    },
})
